import { useCallback, useMemo, useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';


import AppContext from '../store/AppContext.js';
import { speakerOptions } from "../store/TranscriptionSettingsConfig"
import Modal from './ui/Modal';
import Backdrop from './ui/Backdrop';

import PropTypes from 'prop-types';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: "3rem",
  margin: "2%",
  borderWidth: 2,
  borderRadius: '35px',
  borderColor: 'rgba(0, 0, 0, 1)',
  borderStyle: 'dashed',
  backgroundColor: 'rgba(159, 2, 170, 0.2)',
  color: 'rgba(0, 0, 0, 1)',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

/**
* Component for handling the file upload, both the .wav file and .zip file.
*/
function FileUpload(props) {
  FileUpload.propTypes = {
    /** Function for changing the state of the transcription page ["start","loading","loaded"]. */
    state: PropTypes.func,
    /** Calls function *toggleAgentClips* of parent component *TranscriptionTool* */
    toggleAgent: PropTypes.func,
    /** Calls function *toggleClientClips* of parent component *TranscriptionTool* */
    toggleClient: PropTypes.func,
    /** Boolean value that signals if the loaded file should be sent to BE for transcription */
    requestTranscription: PropTypes.bool,

  };

  const context = useContext(AppContext);
  const [modalOpenNewFile, setModalOpenNewFile] = useState(false);


  function openModalOpenNewFile() {
    setModalOpenNewFile(true);
  }
  function closeModalOpenNewFile() {
    setModalOpenNewFile(false);
  }
  function confirmOpenNewFile() {
    props.state("loading");
    loadAudioInfo(context.originalFile);
    props.toggleAgent();
    context.setCurrentId(-1);
    closeModalOpenNewFile();
  }





  const loadAudioInfo = useCallback((file) => {

    // Whether to load from a checkpoint or new raw audio file
    if (file[0]["name"].includes("WIP-") && file[0]["name"].includes(".zip")) {

      context.setAudioName(file[0]["name"].substring(4).slice(0, -4))
      var zip = new JSZip();
      zip.loadAsync(file[0]).then(function (zip) {

        zip.file("amount_clips.json").async("string").then(function (data) {
          let amountClips = JSON.parse(data);

          let agentChannelVal = speakerOptions[0].value;
          let clientChannelVal = speakerOptions[1].value;

          let totalAgentClips = agentChannelVal in amountClips ? amountClips[agentChannelVal] : 0;
          let totalClientClips = clientChannelVal in amountClips ? amountClips[clientChannelVal] : 0;


          var agent_clips = [];
          var client_clips = [];
          let agentTranscribedTotal = 0;
          let clientTranscribedTotal = 0;

          Object.entries(zip.files).forEach((file) => {

            if (file[0].includes("Agent")) {

              let transcribed = file[1]["name"][0] === "1" ? true : false;
              file[1]["name"] = file[1]["name"].substring(1).replace("Agent:", "");
              // remove "part_" and ".wav" characters to leave just the part number
              let part_number = file[1]["name"].substring(5).replace(".wav", "");
              part_number = parseInt(part_number);

              agent_clips.push({ "id": part_number, "file": file[1], "transcribed": transcribed });

              if (transcribed) { agentTranscribedTotal++; }
            }

            if (file[0].includes("Client")) {
              let transcribed = file[1]["name"][0] === "1" ? true : false;
              file[1]["name"] = file[1]["name"].substring(1).replace("Client:", "");
              // remove "part_" and ".wav" characters to leave just the part number
              let part_number = file[1]["name"].substring(5).replace(".wav", "");
              part_number = parseInt(part_number);

              client_clips.push({ "id": part_number, "file": file[1], "transcribed": transcribed });

              if (transcribed) { clientTranscribedTotal++; }
            }

          });

          const status_data = { "agentDone": agentTranscribedTotal, "clientDone": clientTranscribedTotal, "agentTotal": totalAgentClips, "clientTotal": totalClientClips };
          context.setWorkStatus(status_data);

          agent_clips.sort((a, b) => a["id"] - b["id"]);
          client_clips.sort((a, b) => a["id"] - b["id"]);
          context.setAgentAudios(agent_clips);
          context.setClientAudios(client_clips);

          if (totalAgentClips === 0) {
            context.setSingleChannelMode(true);
            props.toggleClient();
          }
          else if (totalClientClips === 0) {
            context.setSingleChannelMode(true);
            props.toggleAgent();
          }

        });

        if ("agentOriginal.json" in zip.files) {
          zip.file("agentOriginal.json").async("string").then(function (data) {
            context.setAgentOriginalTrans(JSON.parse(data));
          });
          zip.file("agentFixed.json").async("string").then(function (data) {
            context.setDecoratedAgentFixedTrans(JSON.parse(data));
          });
        }

        if ("clientOriginal.json" in zip.files) {
          zip.file("clientOriginal.json").async("string").then(function (data) {
            context.setClientOriginalTrans(JSON.parse(data));
          });
          zip.file("clientFixed.json").async("string").then(function (data) {
            context.setDecoratedClientFixedTrans(JSON.parse(data));
          });
        }

        context.setDataIsLoaded(true);
        props.state("loaded");


      });

    } else {
      // Continue with the normal file upload flow
      context.setAudioName(file[0]["name"].slice(0, -4))

      context.setDataIsLoaded(false);
      props.state("preloaded");
      context.setCompleteRecording(URL.createObjectURL(file[0]));
    }

  }, [context, props]);



  const onDrop = useCallback(acceptedFile => {
    context.setOriginalFile(acceptedFile);
    if (context.dataIsLoaded) {
      openModalOpenNewFile();
    } else {
      props.state("loading");
      loadAudioInfo(acceptedFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, loadAudioInfo, context.dataIsLoaded]);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop, acceptedFiles: "file/*", maxFiles: 1 })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);



  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <div>
            <p>Drag 'n' drop a file here, or click to select it...</p>
            <em >(Only ONE *.wav file will be accepted)</em>
          </div>

      }

      {modalOpenNewFile ? <Modal confirm={confirmOpenNewFile} cancel={closeModalOpenNewFile} line1={"You're about to open a new file."} line2={"Are you sure you want to continue?."} /> : null}
      {modalOpenNewFile ? <Backdrop onClick={closeModalOpenNewFile} /> : null}

    </div>
  );
}

export default FileUpload;