import { Grid, Box } from "@material-ui/core";
import { useState, useRef, useContext, useEffect } from "react";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";


import Modal from '../components/ui/Modal';
import Backdrop from '../components/ui/Backdrop';
import AudioPlayerWidget from "../components/AudioPlayerWidget";
import ChannelSwitch from "../components/clips/ChannelSwitch";
import FileUpload from "../components/FileUpload";
import Clips from "../components/clips/Clips";
import AudioPreloadingArea from "../components/AudioPreloadingArea"
import TranscriptionTextArea from "../components/TranscriptionTextArea";
import MetadataButtons from "../components/metadata/MetadataButtons"
import ControlButtons from "../components/ControlButtons";
import AppContext from '../store/AppContext.js';
import useExitPrompt from "../components/hooks/useExitPrompt";
import classes from '../styles/TranscriptionWorkArea.module.css'

/**
* This is the main component of the application, it will handle all the other components.
*/
function TranscriptionTool() {

  const context = useContext(AppContext);
  const data_agent = context.agent_audios;
  const data_client = context.client_audios;



  //state will handle 4 actions: start, preloaded, loading, loaded.
  const [state, setState] = useState("start");
  const [clipsToDisplay, setClipsToDisplay] = useState(context.selectedCategory.charAt(0).toUpperCase() + context.selectedCategory.slice(1));
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const childRefAudioPlayer = useRef();
  const childRefTranscriptionTextArea = useRef();

  const [modalServerFailed, setModalServerFailed] = useState(false);
  const [serverFailedMsg, setServerFailedMsg] = useState("");
  const [modalResponseClipsOmitted, setModalResponseClipsOmitted] = useState(false);
  const [modalSubmitUnlabeledSuccess, setModalSubmitUnlabeledSuccess] = useState(false);


  useEffect(() => {
    setShowExitPrompt(true)
  }, [setShowExitPrompt, showExitPrompt]);


  useEffect(() => {

    if (context.dataIsLoaded) {
      refreshShownClipData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function openModalResponseClipsOmitted() {
    setModalResponseClipsOmitted(true);
  }
  function closeModalResponseClipsOmitted() {
    setModalResponseClipsOmitted(false);
  }
  function openModal(text) {
    setServerFailedMsg(text);
    setModalServerFailed(true);
  }
  function closeModal() {
    setServerFailedMsg("");
    setModalServerFailed(false);
  }

  function openModalSubmitUnlabeledSuccess() {
    setModalSubmitUnlabeledSuccess(true);

  }
  function closeModalSubmitUnlabeledSuccess() {
    setModalSubmitUnlabeledSuccess(false);

  }


  function handleLoadingState(state) {
    setState(state);
  }

  function refreshShownClipData() {
    childRefAudioPlayer.current.handleAudio();
    childRefTranscriptionTextArea.current.handleTranscription();
  }

  /**
  * This function resolves the transcription text, of both text areas, that should be displayed according to the selected clip.
  * It also selects the corresponding audio file of the clip, so it can be properly loaded in the audio player.
  *
  * @public
  */
  function handleClipSelectionChange(newClipID) {
    let files;
    let generatedTranscription;
    let fixedTranscription;
    let transcribed_flag;

    context.setCurrentId(newClipID);

    if (context.selectedCategory === "agent") {

      files = context.agent_audios[newClipID];
      generatedTranscription = context.agent_original_trans[newClipID]["text"];

      fixedTranscription = context.agent_fixed_trans[newClipID]["text"];
      transcribed_flag = context.agent_audios[newClipID]["transcribed"];

    } else if (context.selectedCategory === "client") {

      files = context.client_audios[newClipID];
      generatedTranscription = context.client_original_trans[newClipID]["text"];

      fixedTranscription = context.client_fixed_trans[newClipID]["text"];
      transcribed_flag = context.client_audios[newClipID]["transcribed"];

    }

    if (generatedTranscription === "") { generatedTranscription = "This clip doesn't have any associated transcription. If you conclude that there isn't any speech in the clip, please mark the corresponding option in the <Clip Attributes> field and proceed normally.\n\nIF THERE ALREADY HAVE BEEN A CONSIDERABLE AMOUNT OF SPEECHLESS CLIPS IN THIS CHANNEL, PLEASE CONSIDER DELETING THIS CLIP" }
    if (fixedTranscription === "") { fixedTranscription = "" }


    files.file.async("blob")
      .then(function (data) {
        context.setCurrentClip(URL.createObjectURL(data));
        refreshShownClipData();
      });

    context.setSelectedTranscription({ "generated": generatedTranscription, "fixed": fixedTranscription });
    context.setIsSelectedTranscriptionReady(transcribed_flag);

  }

  function handleFixedTextChange() {
    if (context.selectedCategory === "agent") {
      let data = [...context.agent_fixed_trans];
      data[context.currentId]["text"] = context.fixed_transcription;
      context.setAgentFixedTrans(data);

    } else if (context.selectedCategory === "client") {
      let data = [...context.client_fixed_trans];
      data[context.currentId]["text"] = context.fixed_transcription;
      context.setClientFixedTrans(data);
    }
  }


  function toggleAgentClips() {
    setClipsToDisplay("Agent");
    context.setSelectedCategory("agent");
  }
  function toggleClientClips() {
    setClipsToDisplay("Client");
    context.setSelectedCategory("client");
  }


  let selectedData = [];
  let displayedCategoryText;
  if (clipsToDisplay === "Agent") {
    selectedData = data_agent;
    displayedCategoryText = 'User';
  } else {
    selectedData = data_client;
    displayedCategoryText = 'Lead';
  }

  const onLoadedContent = (
    <Grid container>
      <Grid item xs={2} style={{ height: "52rem" }}>
        <div style={{ height: "8rem" }}>
          <ChannelSwitch
            toggleAgent={toggleAgentClips}
            toggleClient={toggleClientClips}
            updateSelectedClip={handleClipSelectionChange}
          >
          </ChannelSwitch>
        </div>
        <div>
          <Box style={{ maxHeight: "40rem", overflow: "auto", margin: "1rem", marginTop: "4rem" }}>
            {selectedData.map((clip) => (
              <Clips key={clip.id} id={clip.id} transcribed={clip.transcribed} category={displayedCategoryText} updateSelectedClip={handleClipSelectionChange} />
            ))}
          </Box>
        </div>
      </Grid>

      <Grid item xs={10} className={classes.transcriptionGrid}>
        <div className={classes.transcriptionWorkContainer}>
          <TranscriptionTextArea ref={childRefTranscriptionTextArea} updateFixedText={handleFixedTextChange}></TranscriptionTextArea>
        </div>
        <div className={classes.transcriptionWorkContainer}>
          <MetadataButtons />
        </div>
        <div className={classes.transcriptionWorkContainer}>
          <ControlButtons state={handleLoadingState} toggleAgent={toggleAgentClips} updateSelectedClip={handleClipSelectionChange} updateFixedText={handleFixedTextChange}></ControlButtons>
        </div>
      </Grid>
    </Grid>
  );

  const override = `
    display: block;
    margin: 0 auto;
    margin-top: 8rem;
    border-color: red;
    `;

  const onLoadingContent = (
    <Grid container>
      <Grid item xs={12} style={{ height: "10rem" }}>
        <div>
          <ClimbingBoxLoader color={"#540a78"} size={25} css={override} />
          <h3 style={{ textAlign: "center", marginTop: "2rem" }}>Loading...</h3>
        </div>
      </Grid>
    </Grid>
  );

  const onPreLoadedContent = (
    <Grid container>

      <Grid item xs={10} className={classes.transcriptionSettingsGrid}>
        <div className={classes.transcriptionWorkContainer}>
          <AudioPreloadingArea state={handleLoadingState} toggleAgent={toggleAgentClips} toggleClient={toggleClientClips} openModalOmittedMsg={openModalResponseClipsOmitted} openModalErrorMsg={openModal} openModalSubmitUnlabeledSuccess={openModalSubmitUnlabeledSuccess} />
        </div>
      </Grid>

    </Grid>
  );

  const startContent = (
    <Grid container>
      <Grid item xs={12} style={{ height: "10rem" }}>
        <div>
          <h1 style={{ color: "rgba(141, 113, 127, 0.60)", textAlign: "center", marginTop: "15rem" }}>Empty workspace. Start by uploading ONE audio file (.wav)</h1>
        </div>
      </Grid>
    </Grid>
  );

  let content;

  if (state === "loaded" | context.dataIsLoaded) {
    content = onLoadedContent;
  } else if (state === "loading") {
    content = onLoadingContent;
  } else if (state === "preloaded") {
    content = onPreLoadedContent;
  } else if (state === "start") {
    content = startContent;
  }


  return (
    <section>
      <Grid container>
        <Grid item xs={4} style={{ height: "10rem" }}>
          <FileUpload state={handleLoadingState} toggleAgent={toggleAgentClips} toggleClient={toggleClientClips} ></FileUpload>
        </Grid>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={6}>
              <AudioPlayerWidget ref={childRefAudioPlayer} name={context.currentId === -1 ? "Clip Player: No clip selected" : `Playing clip ${context.currentId + 1}`}></AudioPlayerWidget>
            </Grid>
            <Grid item xs={6}>
              <AudioPlayerWidget completeAudio={context.completeRecording} name={"Complete audio recording"}></AudioPlayerWidget>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {content}

      {modalServerFailed ? <Modal accept={closeModal} line1={serverFailedMsg} line2={""} /> : null}
      {modalServerFailed ? <Backdrop onClick={closeModal} /> : null}
      {modalSubmitUnlabeledSuccess ? <Modal accept={closeModalSubmitUnlabeledSuccess} line1={"All files where successfully submited as unlabeled data!"} line2={""} /> : null}
      {modalSubmitUnlabeledSuccess ? <Backdrop onClick={closeModalSubmitUnlabeledSuccess} /> : null}
      {modalResponseClipsOmitted ? <Modal accept={closeModalResponseClipsOmitted} line1={"The server omitted some clips as they were longer than 60s."} line2={""} /> : null}
      {modalResponseClipsOmitted ? <Backdrop onClick={closeModalResponseClipsOmitted} /> : null}
    </section>
  );
}

export default TranscriptionTool;
