import { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import mainMdDocument from '../markdown/guidelines.md';
import secondaryMdDocument from '../markdown/metadata_tagging_guidelines.md'
import { metadataVars } from '../store/MetadataConfig';

/**
* Component for rendering the guidelines info. This component will render all the contents of the markdown file "markdown/guidelines.md"
* This as well as rendering dynamic guidelines info related to clip metadata fields.
*/
function Guidelines(){

    const [mainContent, setMainContent] = useState("");
    const [secondaryContent, setSecondaryContent] = useState("");
    
    useEffect(() => {
        fetch(mainMdDocument)
            .then(res => res.text())
            .then(md => { setMainContent(md) })
    }, []);

    useEffect(() => {
        fetch(secondaryMdDocument)
            .then(res => res.text())
            .then(md => { 
                for (const field of metadataVars){
                    
                    let { displayText, descriptionText } = field;
                    let fieldGuideline = '## -' + displayText + '.\n\n';
                    fieldGuideline = fieldGuideline + descriptionText + '<br/><br/>\n\n';
                    
                    md = md + fieldGuideline;
                }
                setSecondaryContent(md); 
            })
    }, []);

    return(
        <div style={{"marginLeft": "1rem"}}>
            <Markdown children={mainContent} />
            <Markdown children={secondaryContent} />
            
        </div>
    );
}

export default Guidelines;