import { createContext, useState } from "react";
import { metadataVars } from "./MetadataConfig"

const AppContext = createContext({
    originalFile: {},
    audio: {},
    audioName: {},
    selectedTranscription: {},
    singleChannelMode: {},
    selectedCategory: {},
    workStatus: {},
    currentId: {},
    dataIsLoaded: {},
    completeRecording: {},
    agent_audios: [],
    client_audios: [],
    agent_original_trans: [],
    client_original_trans: [],
    agent_fixed_trans: [],
    client_fixed_trans: [],
    fixed_transcription: {},
    setOriginalFile: (originalFile) => { },
    setCurrentClip: (audio) => { },
    setAudioName: (name) => { },
    setSelectedTranscription: (transcription) => { },
    setSingleChannelMode: (mode) => { },
    setSelectedCategory: (category) => { },
    setWorkStatus: (transcription) => { },
    setCurrentId: (id) => { },
    setDataIsLoaded: (status) => { },
    setCompleteRecording: (audio) => { },
    setAgentAudios: (audio) => [],
    setClientAudios: (audio) => [],
    setAgentOriginalTrans: (file) => [],
    setClientOriginalTrans: (file) => [],
    setAgentFixedTrans: (file) => [],
    setClientFixedTrans: (file) => [],
    setDecoratedAgentFixedTrans: (file) => [],
    setDecoratedClientFixedTrans: (file) => [],
    checkInvalidClipMetadata: () => [],
    checkInvalidClipText: () => { },
    setClipMetadataField: (field, value) => [],
    setChannelMetadataField: (field, value) => [],
    markTranscription: (id) => [],
    unmarkTranscription: (id) => [],
    deleteClip: (id) => [],
    calcTotalAgentSeconds: () => [],
    calcTotalClientSeconds: () => [],
});
/**
* This component stores all the app data in a global context, so it can be accessed from any component.
*/
export function AppContextProvider(props) {
    const [uploadedAudio, setUploadedAudio] = useState({});
    const [userAudio, setUserAudio] = useState({});
    const [audioName, setAudioName] = useState({});
    const [selectedTranscription, setSelectedTranscription] = useState({});
    const [isSelectedTranscriptionReady, setIsSelectedTranscriptionReady] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("agent");
    const [singleChannelMode, setSingleChannelMode] = useState(false);
    const [workStatus, setWorkStatus] = useState({});
    const [currentId, setCurrentId] = useState(-1);
    const [dataIsLoaded, setDataIsLoaded] = useState(false);
    const [completeRecording, setCompleteRecording] = useState(-1);
    const [agentAudios, setAgentAudios] = useState([]);
    const [clientAudios, setClientAudios] = useState([]);
    const [agentOriginalTrans, setAgentOriginalTrans] = useState([]);
    const [clientOriginalTrans, setClientOriginalTrans] = useState([]);
    const [agentFixedTrans, setAgentFixedTrans] = useState([]);
    const [clientFixedTrans, setClientFixedTrans] = useState([]);
    const [fixedTranscription, setFixedTranscription] = useState("");

    function setUploadedAudioHandler(audio) {
        setUploadedAudio(audio);
    }

    function setUserAudioHandler(audio) {
        setUserAudio(audio);
    }

    function setAudioNameHandler(name) {
        setAudioName(name);
    }

    function setSelectedTranscriptionHandler(transcription) {
        setSelectedTranscription(transcription);
    }

    function setIsSelectedTranscriptionReadyHandler(ready) {
        setIsSelectedTranscriptionReady(ready);
    }

    function setSingleChannelModeHandler(mode) {
        setSingleChannelMode(mode);
    }

    function setSelectedCategoryHandler(category) {
        setSelectedCategory(category);
    }

    function setWorkStatusHandler(status) {
        setWorkStatus(status);
    }

    function setCurrentIdHandler(id) {
        setCurrentId(id);
    }

    function setDataIsLoadedHandler(status) {
        setDataIsLoaded(status);
    }

    function setCompleteRecordingHandler(audio) {
        setCompleteRecording(audio);
    }

    function setAgentAudiosHandler(clips) {
        setAgentAudios(clips);
    }

    function setClientAudiosHandler(clips) {
        setClientAudios(clips);
    }

    function setAgentOriginalTransHandler(file) {
        setAgentOriginalTrans(file);
    }

    function setClientOriginalTransHandler(file) {
        setClientOriginalTrans(file);
    }

    function setAgentFixedTransHandler(file) {
        setAgentFixedTrans(file);
    }

    function setClientFixedTransHandler(file) {
        setClientFixedTrans(file);
    }

    // Add metadata fields with default values to json if not present
    function decorateFixedTrans(file) {
        for (const field of metadataVars) {
            let { name, defaultOption, options } = field;

            let defaultValue = ((defaultOption === -1) ? null : options[defaultOption]['value']);
            for (const clipObject of file) {
                if (!clipObject.hasOwnProperty(name)) {
                    clipObject[name] = defaultValue;
                }
            }
        }
    }

    // Check whether there are null values for metadata fields of a clip
    function checkInvalidClipMetadata() {
        let data = null;

        if (selectedCategory === "agent") {
            data = [...agentFixedTrans];
        }
        else {
            data = [...clientFixedTrans];
        }

        for (const field of metadataVars) {
            let { name } = field;

            if (data[currentId][name] === null) {
                return true;
            }
        }
        return false;
    }

    function checkInvalidClipTextHandler() {
        let data = null;

        if (selectedCategory === "agent") {
            data = [...agentFixedTrans];
        }
        else {
            data = [...clientFixedTrans];
        }


        let selected_language_value = data[currentId]["language"];

        for (const field of metadataVars) {
            let { name, options } = field;

            if (name === "language") {
                for (const option of options) {
                    let { value, alphabet } = option;

                    if (value === selected_language_value) {
                        return checkInvalidClipText(data[currentId]['text'], alphabet);
                    }
                }
            }


        }
    }

    // Check whether the transcribed text adheres to a given vocabulary
    function checkInvalidClipText(text, alphabet) {

        let clean_text = text.toUpperCase()

        // Ignore correct unk tags in checks
        var re = /(<UNK>)(.*?)(<\/UNK>)/g;
        var static_text = clean_text;
        var match_group;

        do {
            match_group = re.exec(static_text);
            if (match_group) {
                clean_text = clean_text.replace(match_group[0], match_group[2]);
            }
        } while (match_group);


        for (var i = 0; i < clean_text.length; i++) {

            if (!alphabet.has(clean_text[i].toUpperCase())) {
                console.log(`Found unexpected character ${clean_text[i]}`);
                return true;
            }
        }
        return false;
    }

    function setDecoratedAgentFixedTransHandler(file) {
        decorateFixedTrans(file);
        setAgentFixedTrans(file);
    }

    function setDecoratedClientFixedTransHandler(file) {
        decorateFixedTrans(file);
        setClientFixedTrans(file);
    }

    // Update a metadata field for an individual clip
    // Return a bool for whether the field was previously unset (null value) or not
    function updateClipMetadataField(fieldName, newValue) {

        const initialSetCheck = (channelData, currentId, fieldName) => {
            let currentValue = channelData[currentId][fieldName];
            if (currentValue === null) {
                return true;
            }
            return false;
        };

        let initialSet = false;

        if (selectedCategory === "agent") {
            let data = [...agentFixedTrans];
            initialSet = initialSetCheck(data, currentId, fieldName);
            data[currentId][fieldName] = newValue;

            setAgentFixedTrans(data);
        }
        else {
            let data = [...clientFixedTrans];
            initialSet = initialSetCheck(data, currentId, fieldName);
            data[currentId][fieldName] = newValue;

            setClientFixedTrans(data);
        }

        return initialSet;
    }

    function updateChannelMetadataField(fieldName, newValue) {
        let data = null;
        let updateFunc = null;

        if (selectedCategory === "agent") {
            data = [...agentFixedTrans];
            updateFunc = setAgentFixedTrans;

        }
        else {
            data = [...clientFixedTrans];
            updateFunc = setClientFixedTrans;
        }

        for (let i = 0; i < data.length; i++) {
            // change value only for clips that had the field unset
            if (data[i][fieldName] === null) {
                data[i][fieldName] = newValue;
            }
        }
        updateFunc(data);
    }

    function setFixedTranscriptionHandler(transcription) {
        setFixedTranscription(transcription);
    }

    function markTranscriptionHandler(id) {
        if (selectedCategory === "agent") {
            let data = [...agentAudios]
            data[id]["transcribed"] = true;
            setAgentAudios(data);
            updateWorkStatus(data)

        } else if (selectedCategory === "client") {
            let data = [...clientAudios]
            data[id]["transcribed"] = true;
            setClientAudios(data);
            updateWorkStatus(data)
        }

        setIsSelectedTranscriptionReadyHandler(true)
    }
    function unmarkTranscriptionHandler(id, category) {
        if (selectedCategory === "agent") {
            let data = [...agentAudios]
            data[id]["transcribed"] = false;
            setAgentAudios(data);
            updateWorkStatus(data)

        } else if (selectedCategory === "client") {
            let data = [...clientAudios]
            data[id]["transcribed"] = false;
            setClientAudios(data);
            updateWorkStatus(data)

        }

        setIsSelectedTranscriptionReadyHandler(false)
    }

    function deleteClipHandler(id) {
        let data;
        if (selectedCategory === "agent") {
            data = [...agentAudios]
            data.splice(id, 1);
            let counter = 0;
            data.forEach((clip) => {
                clip["id"] = counter;
                counter++;
            });

            setAgentAudios(data);
            let transcripts = [...agentOriginalTrans];
            transcripts.splice(id, 1);
            setCurrentId(0);
            setAgentOriginalTrans(transcripts);
            updateWorkStatus(data)

            transcripts = [...agentFixedTrans];
            transcripts.splice(id, 1);
            setAgentFixedTrans(transcripts);

        } else if (selectedCategory === "client") {
            data = [...clientAudios]
            data.splice(id, 1);
            let counter = 0;
            data.forEach((clip) => {
                clip["id"] = counter;
                counter++;
            });

            setClientAudios(data);
            let transcripts = [...clientOriginalTrans];
            transcripts.splice(id, 1);
            setCurrentId(0);
            setClientOriginalTrans(transcripts);
            updateWorkStatus(data);

            transcripts = [...clientFixedTrans];
            transcripts.splice(id, 1);
            setClientFixedTrans(transcripts);

        }

    }

    function updateWorkStatus(data) {
        let transcribedCounter = 0;
        if (selectedCategory === "agent") {
            data.forEach((clip) => {
                if (clip["transcribed"]) { transcribedCounter++ }
            })
            let status = workStatus
            status["agentDone"] = transcribedCounter
            status["agentTotal"] = data.length
            setWorkStatus(status);

        } else if (selectedCategory === "client") {
            data.forEach((clip) => {
                if (clip["transcribed"]) { transcribedCounter++ }
            })
            let status = workStatus
            status["clientDone"] = transcribedCounter
            status["clientTotal"] = data.length
            setWorkStatus(status);

        }

    }

    function calcTotalAgentSeconds() {
        let data = [...agentFixedTrans];
        return calcTotalSeconds(data);
    }
    function calcTotalClientSeconds() {
        let data = [...clientFixedTrans];
        return calcTotalSeconds(data);
    }
    function calcTotalSeconds(data) {
        let total = 0;

        for (let i = 0; i < data.length; i++) {
            total = total + parseFloat(data[i]['length']);
        }

        total = Math.round((total + Number.EPSILON) * 100) / 100; // Round to two decimal places

        return total;
    }

    const context = {
        originalFile: uploadedAudio,
        audio: userAudio,
        audioName: audioName,
        selected_transcription: selectedTranscription,
        is_selected_transcription_ready: isSelectedTranscriptionReady,
        singleChannelMode: singleChannelMode,
        selectedCategory: selectedCategory,
        workStatus: workStatus,
        currentId: currentId,
        dataIsLoaded: dataIsLoaded,
        completeRecording: completeRecording,
        agent_audios: agentAudios,
        client_audios: clientAudios,
        agent_original_trans: agentOriginalTrans,
        client_original_trans: clientOriginalTrans,
        agent_fixed_trans: agentFixedTrans,
        client_fixed_trans: clientFixedTrans,
        fixed_transcription: fixedTranscription,
        setOriginalFile: setUploadedAudioHandler,
        setCurrentClip: setUserAudioHandler,
        setAudioName: setAudioNameHandler,
        setSelectedTranscription: setSelectedTranscriptionHandler,
        setIsSelectedTranscriptionReady: setIsSelectedTranscriptionReadyHandler,
        setSingleChannelMode: setSingleChannelModeHandler,
        setSelectedCategory: setSelectedCategoryHandler,
        setWorkStatus: setWorkStatusHandler,
        setCurrentId: setCurrentIdHandler,
        setDataIsLoaded: setDataIsLoadedHandler,
        setCompleteRecording: setCompleteRecordingHandler,
        setAgentAudios: setAgentAudiosHandler,
        setClientAudios: setClientAudiosHandler,
        setAgentOriginalTrans: setAgentOriginalTransHandler,
        setClientOriginalTrans: setClientOriginalTransHandler,
        setAgentFixedTrans: setAgentFixedTransHandler,
        setClientFixedTrans: setClientFixedTransHandler,
        checkInvalidClipMetadata: checkInvalidClipMetadata,
        checkInvalidClipText: checkInvalidClipTextHandler,
        setDecoratedAgentFixedTrans: setDecoratedAgentFixedTransHandler,
        setDecoratedClientFixedTrans: setDecoratedClientFixedTransHandler,
        setClipMetadataField: updateClipMetadataField,
        setChannelMetadataField: updateChannelMetadataField,
        setFixedTranscription: setFixedTranscriptionHandler,
        markTranscription: markTranscriptionHandler,
        unmarkTranscription: unmarkTranscriptionHandler,
        deleteClip: deleteClipHandler,
        calcTotalAgentSeconds: calcTotalAgentSeconds,
        calcTotalClientSeconds: calcTotalClientSeconds
    };

    return <AppContext.Provider value={context}>
        {props.children}
    </AppContext.Provider>

}

export default AppContext;