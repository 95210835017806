
/** 
 * Metadata fields for decorating clip transcriptions
 * All variables are managed individually for each clip
 * For metadata tagging, default values can be choosen to pre-tag all clips
 * defaultOption refers to an index of the option inside the options array
 * If defaultOption: -1, then the default value is chosen as null
 * Define each option value as a string for compliance with input component value properies
 * 
 * RESERVED METADATA FIELD NAMES: 
 * text, score, length, id, id_audio, speaker, segment_id, file_path, initial_google_score 
*/
export const metadataVars = [
    {
        name: 'sex',
        displayText: 'Sex of the speaker',
        descriptionText: 'Whether the speaker is male or female',
        defaultOption: -1,
        options: [
            {
                displayText: 'Male',
                value: 'm'
            },
            {
                displayText: 'Female',
                value: 'f'
            }
        ]
    },
    {
        name: 'noise',
        displayText: 'Presence of noise',
        descriptionText: 'Degradation of sound quality according to amount of noise perceived in the clip. Here noise can be understood as any sound apart from the speech of the main speaker, or any digital corruption of the audio, like lag or sudden loss of sound',
        defaultOption: 0,
        options: [
            {
                displayText: 'None',
                value: '0'
            },
            {
                displayText: 'Low',
                value: '1'
            },
            {
                displayText: 'High',
                value: '2'
            }
        ]
    },
    {
        name: 'intelligibility',
        displayText: 'Intelligibility of speech',
        descriptionText: 'How difficult it is to understand what the speaker is saying, based solely on his/her pronunciation; or whether there is any speech at all',
        defaultOption: 0,
        options: [
            {
                displayText: 'Clear',
                value: '0'
            },
            {
                displayText: 'Medium',
                value: '1'
            },
            {
                displayText: 'Difficult',
                value: '2'
            },
            {
                displayText: 'No Speech',
                value: '3'
            }
        ]
    },
    {
        name: 'symbols',
        displayText: 'Mention of symbols/numbers',
        descriptionText: 'Whether numbers or special symbols are mentioned in the clip. For example, any mentions of @ or #.',
        defaultOption: 0,
        options: [
            {
                displayText: 'No',
                value: '0'
            },
            {
                displayText: 'Yes',
                value: '1'
            }
        ]
    },
    {
        // Use the corresponding ISO codes for each new language value, see https://www.w3schools.com/tags/ref_language_codes.asp
        name: 'language',
        displayText: 'Language',
        descriptionText: 'The language being spoken in the clip.',
        defaultOption: -1,
        options: [
            {
                displayText: 'English',
                value: 'en',
                alphabet: new Set(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
                    "'", '.', ',', ':', ';', '!', '?', ' '])
            },
            {
                displayText: 'Spanish',
                value: 'es',
                alphabet: new Set(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Á', 'É', 'Í', 'Ñ', 'Ó', 'Ú', 'Ü',
                    "'", '.', ',', ':', ';', '!', '?', '¡', '¿', ' '])
            }
        ]
    }
];

