import CardClips from "../ui/CardClips";
import classes from "../../styles/Clips.module.css";
import PropTypes from 'prop-types';
import {ReactComponent as CheckIcon} from '../../icons/check.svg';
import {ReactComponent as PendingIcon} from '../../icons/pending.svg';
import {useContext} from 'react';
import AppContext from '../../store/AppContext.js';


/**
 * Component for rendering each audio clip interactive card.
 * It gives a visual representation of the clip status (transcribed/pending).
 */
function Clips(props){
    Clips.propTypes = {
        /** Identifier for each clip. */
        id: PropTypes.number,
        /** Is the clip transcribed. */
        transcribed: PropTypes.bool,
        /** Channel category (Agent/Client). */
        category: PropTypes.string,
        /** Calls function *handleClipSelectionChange* of parent component *TranscriptionTool* */
        updateSelectedClip: PropTypes.func
      };
      
    const context = useContext(AppContext);
    let showSelected = false;
    const clipName = `${props.category} Clip ${props.id + 1}`;

    
    let status;
    

    if(props.transcribed){
        status = <div className={classes.divDown}>
                    <PendingIcon fill="rgba(124, 118, 121, 0.54)" stroke="rgba(124, 118, 121, 0.54)" className={classes.icon}></PendingIcon>
                    <CheckIcon fill="rgba(8, 181, 19, 0.68)" stroke="rgba(8, 181, 19, 0.68)" ></CheckIcon>
                </div>
    }else{
        status = <div className={classes.divDown}>
                    <PendingIcon fill="rgba(234, 94, 24, 0.8)" stroke="rgba(234, 94, 24, 0.8)" className={classes.icon}></PendingIcon>
                    <CheckIcon fill="rgba(124, 118, 121, 0.54)" stroke="rgba(124, 118, 121, 0.54)" ></CheckIcon>
                </div>
    }

    if(props.id === context.currentId){
        showSelected = true;
    }
    return(
        <CardClips customClickEvent={() => props.updateSelectedClip(props.id)} selected={showSelected}>
            <div className={classes.divUp}>{clipName}</div>
            {status}
        </CardClips>
    );
}

export default Clips;