import { Grid } from '@material-ui/core';
import { useContext, useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react'
import PropTypes from 'prop-types';

import AppContext from '../store/AppContext.js';
import CardTextArea from "./ui/CardTextArea";
import classes from '../styles/TranscriptionTextArea.module.css'

/**
* Component for the text areas, only the text area on the right-hand side is editable.
*/
function TranscriptionTextArea(props, ref) {
    TranscriptionTextArea.propTypes = {
        /** Calls function *handleFixedTextChange* of parent component *TranscriptionTool* */
        updateFixedText: PropTypes.func
    };
    const context = useContext(AppContext);
    const [generatedTranscription, setGeneratedTranscription] = useState(["Select a clip to start."])
    const [userTranscription, setUserTranscription] = useState(["Select a clip to start."]);
    const fixedTranscriptionTxtAreaRef = useRef();

    useEffect(() => {
        if (context.currentId !== -1) {
            props.updateFixedText();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.fixed_transcription]);

    function TextChange() {

        let new_value = fixedTranscriptionTxtAreaRef.current.value;

        // Replace any white space character with simple space
        new_value = new_value.replace(/[\n\t\r]+/g, " ");

        setUserTranscriptionHandler(new_value);
        context.setFixedTranscription(new_value);
    }

    function setUserTranscriptionHandler(trancription) {
        setUserTranscription(trancription);
    }

    useImperativeHandle(ref, () => ({

        handleTranscription() {
            setGeneratedTranscription(context.selected_transcription["generated"]);
            setUserTranscriptionHandler(context.selected_transcription["fixed"]);
            context.setFixedTranscription(context.selected_transcription["fixed"]);
        },

    }))

    return (
        <CardTextArea>
            <Grid container>
                <Grid item xs={4} >
                    <h3 className={classes.h3}>Generated Transcription</h3>
                    <textarea id="generatedTrans" rows="18" disabled className={classes.textArea} value={generatedTranscription}></textarea>
                </Grid>
                <Grid item xs={8} >
                    <h3 className={classes.h3}>Area for reviewing and fixing</h3>
                    <textarea id="userTrans" required rows="18" disabled={context.is_selected_transcription_ready} className={classes.textArea} value={userTranscription} ref={fixedTranscriptionTxtAreaRef} onChange={TextChange}></textarea>
                </Grid>
            </Grid>
        </CardTextArea>
    );
}

export default forwardRef(TranscriptionTextArea);