import classes from '../../styles/CardMetadataButtons.module.css';

/**
* Basic card component that wraps around the *MetadataButtons* component
*/
function MetadataButtons(props) {
    return (
        <div className={classes.card}>
            {props.children}
        </div>
    );
}

export default MetadataButtons;