import classes from '../styles/ControlButtons.module.css';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useContext, useEffect, useState } from "react";
import JSZip from 'jszip';

import CardControlButtons from './ui/CardControlButtons';
import Modal from './ui/Modal';
import Backdrop from './ui/Backdrop';
import AppContext from '../store/AppContext.js';
import { saveAs } from 'file-saver';
import { metadataVars } from "../store/MetadataConfig"
import { speakerOptions } from "../store/TranscriptionSettingsConfig"

/**
* Component for rendering all the control buttons ["Delete","Un/Mark clip as correct","Submit Transcription","Save Transcription"]
*/
function ControlButtons(props) {
    ControlButtons.propTypes = {
        /** Function for changing the state of the transcription page ["start","loading","loaded"]. */
        state: PropTypes.func,
        /** Function for switching the channel category to Agent. */
        toggleAgent: PropTypes.func,
        /** Calls function *handleClipSelectionChange* of parent component *TranscriptionTool* */
        updateSelectedClip: PropTypes.func,
        /** Calls function *handleFixedTextChange* of parent component *TranscriptionTool* */
        updateFixedText: PropTypes.func
    };

    const context = useContext(AppContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalSubmit, setModalSubmit] = useState(false);
    const [modalSubmitLoading, setModalSubmitLoading] = useState(false);
    const [modalSubmitSuccess, setModalSubmitSuccess] = useState(false);
    const [modalSubmitFailed, setModalSubmitFailed] = useState(false);
    const [modalDeleteAllClips, setModalDeleteAllClips] = useState(false);
    const [modalMetadataCheck, setModalMetadataCheck] = useState(false);
    const [modalTextCheck, setModalTextCheck] = useState(false);

    useEffect(() => {

        if (context.currentId !== -1) {
            props.updateSelectedClip(context.currentId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalIsOpen]);


    function clipMark() {

        if (context.currentId !== -1) {

            if (context.checkInvalidClipMetadata()) {
                setModalMetadataCheck(true);
            }
            else {

                let transcribed_flag;
                if (context.selectedCategory === "agent") {
                    transcribed_flag = context.agent_audios[context.currentId]["transcribed"];
                } else if (context.selectedCategory === "client") {
                    transcribed_flag = context.client_audios[context.currentId]["transcribed"];
                }


                if (transcribed_flag) {
                    context.unmarkTranscription(context.currentId);
                }
                else {
                    // If clip is not ready, then check first if the text is correct
                    if (context.checkInvalidClipText()) {
                        setModalTextCheck(true);
                    } else {
                        context.markTranscription(context.currentId);

                    }
                }

                props.updateFixedText();

            }

        }
    }

    function deleteClip() {
        if (context.currentId !== -1) {
            if (context.selectedCategory === "agent") {
                if (context.agent_audios.length === 1) {
                    openModalDeleteAllClips();
                }
                else { setModalIsOpen(true); }
            }
            else if (context.selectedCategory === "client") {
                if (context.client_audios.length === 1) {
                    openModalDeleteAllClips();
                }
                else { setModalIsOpen(true); }
            }
        }
    }

    function confirmDeleteClip() {
        if (context.currentId !== -1) {
            setModalIsOpen(false);
            context.deleteClip(context.currentId);

        }
    }

    function closeModal() {
        setModalIsOpen(false);
    }

    function closeModalSubmit() {
        setModalSubmit(false);
    }

    function saveTranscription() {

        let agentChannelVal = speakerOptions[0].value;
        let clientChannelVal = speakerOptions[1].value;

        const amountClips = {}
        amountClips[agentChannelVal] = context.workStatus["agentTotal"];
        amountClips[clientChannelVal] = context.workStatus["clientTotal"];

        var zip = new JSZip();

        let counter = 0;
        context.agent_audios.forEach((audio) => {

            const data = audio.file.async("blob").then((data) => { return data; });
            let audioStatus = audio.transcribed ? "1" : "0";
            zip.file(`${audioStatus}Agent:part_${counter}.wav`, data);
            counter++;
        })

        counter = 0;
        context.client_audios.forEach((audio) => {
            const data = audio.file.async("blob").then((data) => { return data; });
            let audioStatus = audio.transcribed ? "1" : "0";
            zip.file(`${audioStatus}Client:part_${counter}.wav`, data);
            counter++;
        })

        if (context.workStatus["agentTotal"] > 0) {
            zip.file("agentOriginal.json", JSON.stringify(context.agent_original_trans));
            zip.file("agentFixed.json", JSON.stringify(context.agent_fixed_trans));
        }

        if (context.workStatus["clientTotal"] > 0) {
            zip.file("clientOriginal.json", JSON.stringify(context.client_original_trans));
            zip.file("clientFixed.json", JSON.stringify(context.client_fixed_trans));
        }

        zip.file("amount_clips.json", JSON.stringify(amountClips));

        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                saveAs(content, `WIP-${context.audioName}.zip`);
            });
    }

    function submitSuccess() {
        context.setCurrentId(-1);
        context.setSelectedCategory("agent");
        setModalSubmitSuccess(true);
    }

    function submitLoadingModal() {
        setModalSubmitLoading(true);
    }

    function submitFailed() {
        setModalSubmitFailed(true);
    }



    function closeModalSubmitLoading() {
        setModalSubmitLoading(false);
    }

    function closeModalSubmitSuccess() {
        setModalSubmitSuccess(false);
        context.setDataIsLoaded(false);
        props.toggleAgent();
        props.state("start");

    }
    function closeModalSubmitFailed() {
        setModalSubmitFailed(false);
        context.setDataIsLoaded(false);
        context.setSelectedCategory("agent")
        props.state("start");
    }

    function openModalDeleteAllClips() {
        setModalDeleteAllClips(true);
    }


    function closeModalDeleteAllClips() {
        setModalDeleteAllClips(false);
    }

    function closeModalMetadataCheck() {
        setModalMetadataCheck(false);
    }

    function closeModalTextCheck() {
        setModalTextCheck(false);
    }

    function submitTranscription() {
        const clipsAgentDone = context.workStatus["agentDone"];
        const clipsAgentTotal = context.workStatus["agentTotal"];
        const clipsClientDone = context.workStatus["clientDone"];
        const clipsClientTotal = context.workStatus["clientTotal"];

        if (clipsAgentDone === clipsAgentTotal && clipsClientDone === clipsClientTotal) {

            submitLoadingModal();

            const formatOutputClipsData = (id_audio, speaker, channel_fixed_trans) => {
                let transcriptions = [];

                for (let i = 0; i < channel_fixed_trans.length; i++) {
                    let currentClip = {};
                    let currentClipContextData = channel_fixed_trans[i];

                    const segment_id = i.toString();
                    const id = id_audio + '_' + speaker + '_' + segment_id;
                    const file_path = '{data_root}/audio_files/' + id_audio + '/' + speaker + '/part_' + segment_id + '.wav';

                    // Save basic clip data
                    currentClip['id'] = id;
                    currentClip['id_audio'] = id_audio;
                    currentClip['speaker'] = speaker;
                    currentClip['segment_id'] = segment_id;
                    currentClip['file_path'] = file_path;
                    currentClip['length'] = currentClipContextData['length'];
                    currentClip['text'] = currentClipContextData['text'];


                    // Save metadata fields clip values
                    for (const field of metadataVars) {
                        let { name } = field;
                        currentClip[name] = currentClipContextData[name];
                    }

                    transcriptions.push(currentClip);
                }
                return transcriptions;
            };

            let id_audio = context.audioName.toString();
            var zip = new JSZip();

            if (clipsAgentTotal > 0) {
                let speaker = 'agent';
                let channel_fixed_trans = context.agent_fixed_trans;

                let agentTranscriptions = formatOutputClipsData(id_audio, speaker, channel_fixed_trans);

                let counter = 0;
                context.agent_audios.forEach((audio) => {

                    const data = audio.file.async("blob").then((data) => { return data; });
                    zip.folder(context.audioName).folder("agent").file(`part_${counter}.wav`, data);
                    counter++;
                })
                zip.folder(context.audioName).folder("agent").file("transcription.json", JSON.stringify(agentTranscriptions));
            }


            if (clipsClientTotal > 0) {
                let speaker = 'client';
                let channel_fixed_trans = context.client_fixed_trans;

                let clientTranscriptions = formatOutputClipsData(id_audio, speaker, channel_fixed_trans);
                let counter = 0;
                context.client_audios.forEach((audio) => {

                    const data = audio.file.async("blob").then((data) => { return data; });
                    zip.folder(context.audioName).folder("client").file(`part_${counter}.wav`, data);
                    counter++;
                })
                zip.folder(context.audioName).folder("client").file("transcription.json", JSON.stringify(clientTranscriptions));
            }


            zip.generateAsync({ type: "blob" })
                .then(function (content) {

                    let formData = new FormData();
                    formData.append('file', content);
                    formData.append('file_name', context.audioName);


                    fetch('https://labeling-tool-be.ai.development.spartanapproach.com/api/v1/upload-zip',
                        {
                            method: "POST",
                            body: formData,
                            headers: {
                                Accept: "text/*"
                            }
                        }).then((response) => {
                            if (!response.ok) {
                                throw Error(response.statusText);
                            }
                            return response;

                        }).then((response) => {
                            closeModalSubmitLoading();
                            submitSuccess();

                        }).catch(error => {
                            console.log(error);

                            closeModalSubmitLoading();
                            submitFailed();
                            // Download intermediate tool-compatible file for subsequent upload retries
                            saveTranscription();
                            // Download final file that failed to upload
                            saveAs(content, `${context.audioName}.zip`);
                        });


                });

        } else {
            setModalSubmit(true);
        }
    }

    let markButtonText = "Mark Clip as correct";

    if (context.currentId !== -1) {
        if (context.selectedCategory === "agent") {
            if (context.agent_audios[context.currentId]["transcribed"]) {
                markButtonText = "Unmark Clip as correct"
            } else {
                markButtonText = "Mark Clip as correct"
            }
        } else if (context.selectedCategory === "client") {
            if (context.client_audios[context.currentId]["transcribed"]) {
                markButtonText = "Unmark Clip as correct"
            } else {
                markButtonText = "Mark Clip as correct"
            }
        }
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={6} >
                    <CardControlButtons>
                        <div>
                            <h4 className={classes.center} >Clip Options</h4>
                            <div className={classes.center}>
                                <button onClick={deleteClip} className={classes.btn}>Delete Clip</button>
                                <button onClick={clipMark} className={classes.btn}>{markButtonText}</button>
                            </div>
                        </div>
                    </CardControlButtons>
                </Grid>
                <Grid item xs={6} >
                    <CardControlButtons>
                        <div>
                            <h4 className={classes.center} >Transcription Options</h4>
                            <div className={classes.center}>
                                <button onClick={submitTranscription} className={classes.btn}>Submit Transcription</button>
                                <button onClick={saveTranscription} className={classes.btn}>Save Transcription</button>
                            </div>
                        </div>
                    </CardControlButtons>
                </Grid>
                {modalIsOpen ? <Modal confirm={confirmDeleteClip} cancel={closeModal} line1={"Are you sure you want to delete this clip?"} line2={"This action cannot be undone."} /> : null}
                {modalIsOpen ? <Backdrop onClick={closeModal} /> : null}
                {modalSubmit ? <Modal accept={closeModalSubmit} line1={"Can't submit yet! you still have pending clips."} line2={"These may need to be transcribed or deleted."} /> : null}
                {modalSubmit ? <Backdrop onClick={closeModalSubmit} /> : null}
                {modalSubmitLoading ? <Modal line1={"Uploading the transcription."} line2={"Please wait a moment."} /> : null}
                {modalSubmitLoading ? <Backdrop /> : null}
                {modalSubmitSuccess ? <Modal accept={closeModalSubmitSuccess} line1={"The transcription has been successfully uploaded! Thank you!"} line2={"You will return to the starting page."} /> : null}
                {modalSubmitSuccess ? <Backdrop onClick={closeModalSubmitSuccess} /> : null}
                {modalSubmitFailed ? <Modal accept={closeModalSubmitFailed} line1={"The transcription upload failed, downloading files locally."} line2={"You will return to the starting page. To try again you can use the downloaded 'WIP-*.zip' file."} /> : null}
                {modalSubmitFailed ? <Backdrop onClick={closeModalSubmitFailed} /> : null}
                {modalDeleteAllClips ? <Modal accept={closeModalDeleteAllClips} line1={"You're about to delete the last clip of this channel."} line2={"This is not an expected use."} /> : null}
                {modalDeleteAllClips ? <Backdrop onClick={closeModalDeleteAllClips} /> : null}
                {modalMetadataCheck ? <Modal accept={closeModalMetadataCheck} line1={"Some clip attributes haven't been selected yet."} line2={"Please select them before continuing."} /> : null}
                {modalMetadataCheck ? <Backdrop onClick={closeModalMetadataCheck} /> : null}
                {modalTextCheck ? <Modal accept={closeModalTextCheck} line1={"Found unexpected characters for the selected language."} line2={"Try again after checking for weird characters or incorrect uses of <unk></unk>"} /> : null}
                {modalTextCheck ? <Backdrop onClick={closeModalTextCheck} /> : null}

            </Grid>
        </div>
    );
}

export default ControlButtons;
