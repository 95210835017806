export const speakerOptions = [
    {
        name: 'agent',
        displayText: 'User',
        value: 'agent'
    },
    {
        name: 'client',
        displayText: 'Lead',
        value: 'client'
    }
]

export const transcriptionSettingsVars = {
    channel1Speakers: {
        name: 'channel1',
        displayText: 'Audio Channel 1',
        defaultOption: 0,
        options: [...speakerOptions]
    },
    channel2Speakers: {
        name: 'channel2',
        displayText: 'Audio Channel 2',
        defaultOption: 0,
        options: [...speakerOptions]
    },
    models: {
        defaultOption: 0,
        options: [
            {
                name: 'google_en',
                displayText: 'Google Speech-to-Text EN',
                value: 'google_en'
            },
            {
                name: 'google_es',
                displayText: 'Google Speech-to-Text ES',
                value: 'google_es'
            },
            {
                name: 'sparta_hearing',
                displayText: 'Sparta Hearing latest deployed',
                value: 'sparta_hearing'
            }
        ]
    },
    disabledChannel: {
        name: 'disabled-channel',
        displayText: '',
        defaultOption: 0,
        options: [
            {
                name: 'no',
                displayText: 'No',
                value: '-1'
            },
            ...speakerOptions
        ]
    },
    audioProcessingOption: {
        name: 'audio-processing-option',
        displayText: '',
        defaultOption: 0,
        options: [
            {
                name: 'labeled',
                displayText: 'Labeled',
                value: 'labeled'
            },
            {
                name: 'unlabeled',
                displayText: 'Unlabeled',
                value: 'unlabeled'
            }
        ]
    }
};