import { Grid } from '@material-ui/core';
import { useContext, useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';

import JSZip from 'jszip';

import AppContext from '../store/AppContext.js';
import CardTextArea from "./ui/CardTextArea";
import classes from '../styles/AudioPreloadingArea.module.css'
import TranscriptionSettingField from './trascriptionSettings/TranscriptionSettingField.js';
import { transcriptionSettingsVars, speakerOptions } from "../store/TranscriptionSettingsConfig"

import Button from '@mui/material/Button';



/**
* Component for the audio pre-loading process.
*/
function AudioPreloadingArea(props) {
    AudioPreloadingArea.propTypes = {
        /** Function for changing the state of the transcription page ["start","loading","loaded"]. */
        state: PropTypes.func,
        /** Calls function *toggleAgentClips* of parent component *TranscriptionTool* */
        toggleAgent: PropTypes.func,
        /** Calls function *toggleClientClips* of parent component *TranscriptionTool* */
        toggleClient: PropTypes.func,
        /** Calls function *openModalResponseClipsOmitted* of parent component *TranscriptionTool* */
        openModalOmittedMsg: PropTypes.func,
        /** Calls function *openModal* of parent component *TranscriptionTool* */
        openModalErrorMsg: PropTypes.func,
        /** Calls function *openModalSubmitUnlabeledSuccess* of parent component *TranscriptionTool* */
        openModalSubmitUnlabeledSuccess: PropTypes.func,
    };

    const context = useContext(AppContext);

    const [speakerChannel1, setSpeakerChannel1] = useState(speakerOptions[1].value);
    const [speakerChannel2, setSpeakerChannel2] = useState(speakerOptions[0].value);
    const [disabledChannel, setDisabledChannel] = useState(transcriptionSettingsVars.disabledChannel.options[transcriptionSettingsVars.disabledChannel.defaultOption].value);
    const [transcriptionModel, setTranscriptionModel] = useState(transcriptionSettingsVars.models.options[transcriptionSettingsVars.models.defaultOption].value);
    const [audioProcessingOption, setAudioProcessingOption] = useState(transcriptionSettingsVars.audioProcessingOption.options[transcriptionSettingsVars.audioProcessingOption.defaultOption].value);
    const [confirmButtonClass, setConfirmButtonClass] = useState(classes.confirmSettingsButtonDefault);
    const [numberOfChannels, setNumberOfChannels] = useState(0);
    const isPlaying = useRef(false);
    const audioElement = useRef();
    const prevAudioPath = useRef();
    useEffect(() => {
        prevAudioPath.current = context.completeRecording;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const audioCtx = useRef(new AudioContext());
    const volumeNodeL = useRef();
    const volumeNodeR = useRef();





    // If finished preloading flow, then request file transcription and load its info
    const loadAudioInfoFromBE = useCallback(() => {

        // If audio is still playing, stop it
        if (isPlaying.current) {
            playPause(0);
        }


        props.state("loading");

        let channel_information;

        if (numberOfChannels === 1) {
            channel_information = [
                {
                    "speaker": speakerChannel1,
                    "disabled": false
                }
            ];
        }
        else if (numberOfChannels === 2) {
            channel_information = [
                {
                    "speaker": speakerChannel1,
                    "disabled": disabledChannel === speakerChannel1 ? true : false
                },
                {
                    "speaker": speakerChannel2,
                    "disabled": disabledChannel === speakerChannel2 ? true : false
                }
            ];
        }

        let formData = new FormData();
        formData.append('file', context.originalFile[0]);
        formData.append('channel_information', JSON.stringify(channel_information));

        // If chose unlabeled, then process the audio as such
        if (audioProcessingOption === "unlabeled") {
            formData.append('file_name', context.audioName);

            fetch('https://labeling-tool-be.ai.development.spartanapproach.com/api/v1/upload-zip/process-single-unlabeled-and-upload',
                {
                    method: "POST",
                    body: formData,
                    headers: {
                        Accept: "text/*"
                    }
                }).then((response) => {

                    if (!response.ok) {
                        // Assumes the body of the error response is a json
                        return response.json().then(body => { throw Error(body.detail) });
                    }

                    return response;

                }).then(function () {
                    props.openModalSubmitUnlabeledSuccess();
                    props.state("start");

                }).catch(error => {
                    console.log(error);
                    props.state("start");
                    props.openModalErrorMsg(error.message);
                });

        } else {

            // else process the audio normally
            formData.append('model', transcriptionModel);


            fetch('https://labeling-tool-be.ai.development.spartanapproach.com/api/v1/transcribe-file',
                {
                    method: "POST",
                    body: formData,
                    headers: {
                        Accept: "text/*"
                    }
                }).then((response) => {

                    if (!response.ok) {
                        // Assumes the body of the error response is a json
                        return response.json().then(body => { throw Error(body.detail) })
                    }

                    if (response.status === 220) {
                        props.openModalOmittedMsg();
                    }
                    return response;

                })
                .then((response) => response.blob())
                .then(data => {

                    var zip = new JSZip();
                    zip.loadAsync(data).then(function (zip) {

                        zip.file("amount_clips.json").async("string").then(function (data) {
                            let amountClips = JSON.parse(data);

                            let agentChannelVal = speakerOptions[0].value;
                            let clientChannelVal = speakerOptions[1].value;

                            let totalAgentClips = agentChannelVal in amountClips ? amountClips[agentChannelVal] : 0;
                            let totalClientClips = clientChannelVal in amountClips ? amountClips[clientChannelVal] : 0;

                            const status_data = { "agentDone": 0, "clientDone": 0, "agentTotal": totalAgentClips, "clientTotal": totalClientClips }
                            context.setWorkStatus(status_data);

                            var agent_clips = [];
                            var client_clips = [];
                            for (let i = 0; i < totalAgentClips; i++) {
                                agent_clips.push({ "id": i, "file": zip.files[`clip_agent-part_${i}.wav`], "transcribed": false });
                            }

                            for (let i = 0; i < totalClientClips; i++) {
                                client_clips.push({ "id": i, "file": zip.files[`clip_client-part_${i}.wav`], "transcribed": false });
                            }

                            context.setAgentAudios(agent_clips);
                            context.setClientAudios(client_clips);

                            if (totalAgentClips === 0) {

                                context.setSingleChannelMode(true);
                                props.toggleClient();
                            }
                            else if (totalClientClips === 0) {
                                context.setSingleChannelMode(true);
                                props.toggleAgent();
                            }
                            else context.setSingleChannelMode(false);
                        });

                        if ("transcription-agent.json" in zip.files) {
                            zip.file("transcription-agent.json").async("string").then(function (data) {
                                context.setAgentOriginalTrans(JSON.parse(data));
                                context.setDecoratedAgentFixedTrans(JSON.parse(data));
                            });
                        }

                        if ("transcription-client.json" in zip.files) {
                            zip.file("transcription-client.json").async("string").then(function (data) {
                                context.setClientOriginalTrans(JSON.parse(data));
                                context.setDecoratedClientFixedTrans(JSON.parse(data));
                            });
                        }

                    }).then(function () {
                        context.setDataIsLoaded(true);
                        props.state("loaded");
                    });

                })
                .catch(error => {
                    console.log(error);
                    props.state("start");
                    props.openModalErrorMsg(error.message);
                });
        }

    }, [context, props,
        speakerChannel1, speakerChannel2,
        disabledChannel, numberOfChannels,
        transcriptionModel, isPlaying,
        audioProcessingOption]);


    function switchChannels() {
        let previousValChannel1 = speakerChannel1;
        setSpeakerChannel1(speakerChannel2);
        setSpeakerChannel2(previousValChannel1);
    }

    function setDisabledChannelHandler(event) {
        setDisabledChannel(event.target.value);
    }

    function setTranscriptionModelHandler(event) {
        setTranscriptionModel(event.target.value);
    }

    function setAudioProcessingOptionHandler(event) {
        let value = event.target.value;

        if (value === 'unlabeled') {
            setConfirmButtonClass(classes.confirmSettingsButtonUnlabeled);
        } else {
            setConfirmButtonClass(classes.confirmSettingsButtonDefault);
        }

        setAudioProcessingOption(event.target.value);
    }

    // Couple of functions used to get the number of channels in an
    // audio file. As obtained from 
    // https://stackoverflow.com/questions/46245594/getting-number-of-audio-channels-for-an-audiotrack
    function loadBuffer(path) {
        return fetch(path)
            .then(response => response.arrayBuffer())
            .then(
                buffer =>
                    new Promise((resolve, reject) =>
                        audioCtx.current.decodeAudioData(
                            buffer,
                            data => resolve(data),
                            err => reject(err)
                        )
                    )
            )
    }
    // Also includes solution for playing individual channels of audio.
    // As obtained from
    // https://stackoverflow.com/questions/20644328/using-channelsplitter-and-mergesplitter-nodes-in-web-audio-api/63272648#63272648
    useEffect(() => {

        if (context.completeRecording !== -1 && prevAudioPath.current !== context.completeRecording) {
            loadBuffer(context.completeRecording)
                .then(
                    data => {
                        setNumberOfChannels(data.numberOfChannels);

                        audioElement.current = new Audio(context.completeRecording);

                        const audioSource = audioCtx.current.createMediaElementSource(audioElement.current);

                        volumeNodeL.current = new GainNode(audioCtx.current);
                        volumeNodeR.current = new GainNode(audioCtx.current);

                        volumeNodeL.current.gain.value = 2;
                        volumeNodeR.current.gain.value = 2;

                        const splitterNode = new ChannelSplitterNode(audioCtx.current, { numberOfOutputs: data.numberOfChannels });
                        const mergerNode = new ChannelMergerNode(audioCtx.current, { numberOfInputs: data.numberOfChannels });

                        audioSource.connect(splitterNode);

                        splitterNode.connect(volumeNodeL.current, 0); // connect OUTPUT channel 0
                        volumeNodeL.current.connect(mergerNode, 0, 0); // connect INPUT channel 0

                        // If audio is stereo then also connect second channel
                        if (data.numberOfChannels === 2) {
                            splitterNode.connect(volumeNodeR.current, 1); // connect OUTPUT channel 1
                            volumeNodeR.current.connect(mergerNode, 0, 1); // connect INPUT channel 1
                        }


                        mergerNode.connect(audioCtx.current.destination);
                    }
                ).catch(error => { console.log(error) });
        }
    }, [context.completeRecording]);

    function playPause(val) {

        volumeNodeL.current.gain.value = 1 - val;
        volumeNodeR.current.gain.value = 1 + val;


        // check if context is in suspended state (autoplay policy)
        if (audioCtx.current.state === 'suspended') {
            audioCtx.current.resume();
        }

        isPlaying.current = !isPlaying.current;
        if (isPlaying.current) {
            audioElement.current.play();
        } else {
            audioElement.current.pause();
        }
    }

    let stereoAudioConditionalContent;
    if (numberOfChannels === 2) {
        stereoAudioConditionalContent = (
            <div>

                <TranscriptionSettingField settingFieldInfo={transcriptionSettingsVars.channel2Speakers} settingFieldValue={speakerChannel2} changeSettingValue={switchChannels}>
                    <Button variant="contained" className={classes.listenButton} onClick={() => playPause(1)}>Listen</Button>
                </TranscriptionSettingField>

                <br />
                <h4 className={classes.h4}>Disable the transcription for a speaker type?</h4>


                <TranscriptionSettingField settingFieldInfo={transcriptionSettingsVars.disabledChannel} settingFieldValue={disabledChannel} changeSettingValue={setDisabledChannelHandler} />

            </div>
        );
    }

    let modelConditionalContent;
    if (audioProcessingOption === "labeled") {
        modelConditionalContent = (
            <div>

                <h4 className={classes.h4}>Select the transcription model to use:</h4>

                <TranscriptionSettingField settingFieldInfo={transcriptionSettingsVars.models} settingFieldValue={transcriptionModel} changeSettingValue={setTranscriptionModelHandler} />

            </div>
        );

    }

    return (

        <div className={classes.mainSettingsContainer}>
            <CardTextArea >
                <h3 className={classes.h3}>Please review the following transcription settings:</h3>

                <Grid container>

                    <Grid item xs={8} >
                        <h4 className={classes.h4}>Select which audio channel corresponds to which speaker type:</h4>


                        <TranscriptionSettingField settingFieldInfo={transcriptionSettingsVars.channel1Speakers} settingFieldValue={speakerChannel1} changeSettingValue={switchChannels}>
                            <Button variant="contained" className={classes.listenButton} onClick={() => playPause(-1)}>Listen</Button>
                        </TranscriptionSettingField>

                        {stereoAudioConditionalContent}
                        <br />

                        <h4 className={classes.h4}>Select how to process the audio:</h4>

                        <TranscriptionSettingField settingFieldInfo={transcriptionSettingsVars.audioProcessingOption} settingFieldValue={audioProcessingOption} changeSettingValue={setAudioProcessingOptionHandler} />

                        {modelConditionalContent}

                    </Grid>
                    <Grid item xs={4} className={classes.settingsConfirmContainer}>
                        <Button variant="contained" className={confirmButtonClass} onClick={loadAudioInfoFromBE}>Confirm</Button>
                    </Grid>
                </Grid>
            </CardTextArea>


        </div >
    );
}

export default AudioPreloadingArea;