import classes from '../../styles/MetadataButtons.module.css';
import { useContext, useState } from "react";
import PropTypes from 'prop-types';
import AppContext from '../../store/AppContext.js';

import Modal from '../ui/Modal';
import Backdrop from '../ui/Backdrop';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';



/**
* Component for rendering an individual metadata input field
*/
function MetadataField(props) {
    MetadataField.propTypes = {
        /** Object with all information related to the metadata field */
        metadataFieldInfo: PropTypes.object,
        /** Current metadata value for the clip */
        metadataFieldValue: PropTypes.string

    };

    const [modalInitialSet, setModalInitialSet] = useState(false);

    const context = useContext(AppContext);
    const { name, displayText, options } = props.metadataFieldInfo;

    const updateField = (event) => {
        let initialSet = context.setClipMetadataField(name, event.target.value);

        if (initialSet) {
            setModalInitialSet(true);
        }
    };

    function closeModal() {
        setModalInitialSet(false);
    }

    function confirmSetChannelMetadata() {
        if (context.currentId !== -1) {
            setModalInitialSet(false);
            context.setChannelMetadataField(name, props.metadataFieldValue);

        }
    }

    return (


        <FormControl className={classes.metadataInputField}>
            <FormLabel id={name + "row-radio-buttons-group-label"}>{displayText}</FormLabel>
            <RadioGroup
                row
                aria-labelledby={name + "row-radio-buttons-group-label"}
                name={name + "row-radio-buttons-group"}
                value={props.metadataFieldValue}
                onChange={updateField}
            >

                {options.map((option) => (
                    <FormControlLabel key={name + '-input-option-' + option.value.toString()}
                        value={option.value}
                        control={<Radio className={classes.metadataInputRadioButton} />}
                        label={option.displayText}
                        disabled={context.is_selected_transcription_ready} />
                ))}

            </RadioGroup>

            {modalInitialSet ? <Modal confirm={confirmSetChannelMetadata} cancel={closeModal} line1={"Also use this value for all remaining channel clips with this field unset?"} line2={"You will still be able to change it afterwards for each clip."} /> : null}
            {modalInitialSet ? <Backdrop onClick={closeModal} /> : null}
        </FormControl>

    );
}

export default MetadataField;