import { useContext, useEffect, useRef } from 'react';
import { Grid } from "@material-ui/core";
import PropTypes from 'prop-types';
import classes from '../../styles/ChannelSwitch.module.css';
import AppContext from '../../store/AppContext.js';


/**
* Component for switching between the two audio channels (Agent/Client)
*/
function ChannelSwitch(props) {
    ChannelSwitch.propTypes = {
        /** Calls function *toggleAgentClips* of parent component *TranscriptionTool* */
        toggleAgent: PropTypes.func,
        /** Calls function *toggleClientClips* of parent component *TranscriptionTool* */
        toggleClient: PropTypes.func,
        /** Calls function *handleClipSelectionChange* of parent component *TranscriptionTool* */
        updateSelectedClip: PropTypes.func
    };

    const context = useContext(AppContext);
    const prevSelectedCategory = useRef(context.selectedCategory);



    let content;

    function setAgentCategory() {
        if (context.currentId !== -1) { context.setCurrentId(0); }
        context.setSelectedCategory("agent")
        props.toggleAgent();
    }

    function setClientCategory() {
        if (context.currentId !== -1) { context.setCurrentId(0); }
        context.setSelectedCategory("client")
        props.toggleClient();
    }

    useEffect(() => {
        if (context.currentId !== -1 && context.selectedCategory !== prevSelectedCategory.current) {

            prevSelectedCategory.current = context.selectedCategory;
            props.updateSelectedClip(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.selectedCategory]);



    if (context.selectedCategory === "agent") {

        content = <div style={{ margin: "0 0.5rem" }}>
            <Grid container>
                <Grid item xs={6}>
                    <button onClick={setAgentCategory} className={classes.selected}>
                        {"User Clips"}
                    </button>
                </Grid>
                <Grid item xs={6}>
                    <button onClick={setClientCategory} className={classes.btn} disabled={context.singleChannelMode}>
                        {"Lead Clips"}
                    </button>
                </Grid>
            </Grid>
            <div style={{ textAlign: "center" }}>
                <button className={classes.btnBadge}>
                    {"Clips Done: "}
                    <span className={classes.badgeSelected}>{`${context.workStatus["agentDone"]} / ${context.workStatus["agentTotal"]}`}</span>
                </button>
                <button className={classes.btnBadgeLength}>
                    {"Time: "}
                    <span>{`${context.calcTotalAgentSeconds()} s`}</span>
                </button>
            </div>
        </div>

    } else if (context.selectedCategory === "client") {

        content = <div style={{ margin: "0 0.5rem" }}>
            <Grid container>
                <Grid item xs={6}>
                    <button onClick={setAgentCategory} className={classes.btn} disabled={context.singleChannelMode}>
                        {"User Clips"}
                    </button>
                </Grid>
                <Grid item xs={6}>
                    <button onClick={setClientCategory} className={classes.selected}>
                        {"Lead Clips"}
                    </button>
                </Grid>
            </Grid>
            <div style={{ textAlign: "center" }}>
                <button className={classes.btnBadge}>
                    {"Clips Done: "}
                    <span className={classes.badgeSelected}>{`${context.workStatus["clientDone"]} / ${context.workStatus["clientTotal"]}`}</span>
                </button>
                <button className={classes.btnBadgeLength}>
                    {"Time: "}
                    <span>{`${context.calcTotalClientSeconds()} s`}</span>
                </button>
            </div>
        </div>

    }
    return (
        <div>{content}</div>
    );

}

export default ChannelSwitch;