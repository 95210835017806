import classes from '../../styles/MetadataButtons.module.css';
import { Grid } from '@material-ui/core';
import { useContext } from "react";

import CardMetadataButtons from '../ui/CardMetadataButtons';
import AppContext from '../../store/AppContext.js';
import { metadataVars } from "../../store/MetadataConfig"

import MetadataField from './MetadataField'


/**
* Component for rendering all the metadata input fields 
*/
function MetadataButtons(props) {

    const context = useContext(AppContext);
    let clipMetadata = (context.selectedCategory === 'agent') ? context.agent_fixed_trans : context.client_fixed_trans;

    let inputFields = [];
    if (context.currentId !== -1) {
        clipMetadata = [...clipMetadata][context.currentId]

        inputFields = (
            <div className={classes.center}>

                {metadataVars.map((field) => (
                    <MetadataField key={field['name'] + "-input-field"}
                        metadataFieldInfo={field}
                        metadataFieldValue={clipMetadata[field['name']]} />
                ))}

            </div>
        );

    }

    return (
        <div>
            <Grid container>
                <Grid item xs={12} >
                    <CardMetadataButtons>
                        <div>
                            <h4 className={classes.h4} >Clip Attributes</h4>

                            {inputFields}

                        </div>
                    </CardMetadataButtons>
                </Grid>


            </Grid>
        </div>
    );
}

export default MetadataButtons;
