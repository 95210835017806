import classes from '../../styles/AudioPreloadingArea.module.css'
import PropTypes from 'prop-types';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';




/**
* Component for rendering an individual metadata input field
*/
function TranscriptionSettingField(props) {
    TranscriptionSettingField.propTypes = {
        /** Object with all information related to the metadata field */
        settingFieldInfo: PropTypes.object,
        /** Current setting value*/
        settingFieldValue: PropTypes.string,
        /** */
        changeSettingValue: PropTypes.func
    };

    const { name, displayText, options } = props.settingFieldInfo;


    return (

        <FormControl className={classes.settingContainer}>
            <FormLabel id={name + "-setting-radio-buttons-group-label"} className={classes.settingLabel}>{displayText}</FormLabel>
            <RadioGroup
                row
                aria-labelledby={name + "-setting-radio-buttons-group-label"}
                name={name + "-row-setting-radio-buttons-group"}
                value={props.settingFieldValue}
                onChange={props.changeSettingValue}
                className={classes.settingRadioGroup}
            >

                {options.map((option) => (
                    <FormControlLabel key={option.name + '-' + name + '-input-option-' + option.value}
                        value={option.value}
                        control={<Radio className={classes.settingRadioButton} />}
                        label={option.displayText} />
                ))}

            </RadioGroup>

            {props.children}


        </FormControl>


    );
}

export default TranscriptionSettingField;